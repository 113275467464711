import React, { useState, useEffect } from 'react';
import { useStore } from '@nanostores/react';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

const SpeechSynthesisInfoComponent: React.FC = () => {
  const [data, setData] = useState({ hash: '', data: null });
  const store = useStore(globalStore);

  useEffect(() => {
    if ('speechSynthesis' in window) {
      const synth = window.speechSynthesis;
      const handleVoicesChanged = () => {
        const voices = synth.getVoices();
        const dataToHash = {
          apiStatus: true,
          voices: voices.map((voice) => ({
            name: voice.name,
            lang: voice.lang,
            default: voice.default,
          })),
        };
        const collectSpeechData = async () => {
          const hashValue = await getHash(dataToHash);
          setData({ hash: hashValue, data: dataToHash });
          globalStore.set({ ...globalStore.get(), speech_synthesis: { hash: hashValue, data: dataToHash } });
        };
        collectSpeechData();
      };
      synth.addEventListener('voiceschanged', handleVoicesChanged);
      handleVoicesChanged();
      return () => {
        synth.removeEventListener('voiceschanged', handleVoicesChanged);
      };
    } else {
      setData({ hash: '', data: { apiStatus: false, voices: [] } });
    }
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        Speech <span className="text-sm border px-1">{data.hash.slice(0, 12)}</span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={data.data} />
      </div>
    </div>
  );
};

export default SpeechSynthesisInfoComponent;
